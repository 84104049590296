<template>
  <div class="page" :loaded="Boolean(page) && Boolean(networks)">
    <section class="top-section" v-if="page && page.top">
      <div class="wrapper">
        <div class="container">
          <div class="content">
            <div class="title">BIC Network</div>
            <h3>{{page.top.title}}</h3>
            <h5>{{page.top.subtitle}}</h5>
            <p>{{page.top.description}}</p>
            <div class="buttons">
              <router-link to="/contact" class="btn">Contact us</router-link>
            </div>
          </div>
          <div class="section-image">
            <img :src="page.top.image" alt="">
          </div>
        </div>
      </div>
    </section>

    <section class="what-we-do" v-if="page && page.irish">
      <div class="wrapper large">
        <div class="container">
          <div class="image-container">
            <div class="image">
              <img :src="page.irish.image" alt="" />
            </div>
          </div>
          <div class="content">
            <h3>{{page.irish.title}}</h3>
            <h5>{{page.irish.subtitle}}</h5>
            <p>{{page.irish.description}}</p>
          </div>
        </div>
      </div>
    </section>

    <section class="networks">
      <div class="wrapper">
        <div class="container">
          <a
            class="network"
            v-for="network in networks"
            :key="network.id"
            :href="network.link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img :src="network.image" :alt="network.title" class="network-image">
            <Icon type="fas" size="small" icon="external-link-alt" />
          </a>
        </div>
      </div>
    </section>

    <section class="what-we-do" v-if="page && page.eu">
      <div class="wrapper large">
        <div class="container">
          <div class="image-container">
            <div class="image">
              <img :src="page.eu.image" alt="" />
            </div>
          </div>
          <div class="content">
            <h3>{{page.eu.title}}</h3>
            <h5>{{page.eu.subtitle}}</h5>
            <p>{{page.eu.description}}</p>
          </div>
        </div>
      </div>
    </section>

    <section class="video" v-if="page && page.video">
      <div class="wrapper small">
        <h4>{{page.video.title}}</h4>
        <div class="downloads" v-if="page.video.files">
          <a v-for="file in page.video.files" :key="file.id" :href="`/download/${file.id}`" target="_blank" class="btn small">
            <div class="ic ic-file"></div>
            {{file.title}}
          </a>
        </div>
        <div class="iframe">
          <iframe class="ql-video" frameborder="0" allowfullscreen="true" :src="page.video.link"></iframe>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Icon from "./Icon.vue";

export default {
  name: "Network",
  components: {
    Icon,
  },
  data() {
    return {
      page: null,
      networks: null,
    };
  },
  computed: {
    db() {
      return this.$store.state.firebase.firestore;
    },
    ...mapGetters({
      testMode: "getTest",
    }),
  },
  watch: {
    testMode() {
      this.getItems();
    },
  },
  methods: {
    getItems() {
      if (this.pageRef) this.pageRef();
      if (this.networksRef) this.networksRef();
      this.pageRef = this.db()
        .collection(`${this.testMode ? "test_" : ""}pages`)
        .doc("networks")
        .onSnapshot((page) => {
          this.page = page.data();
        });
      this.networksRef = this.db()
        .collection(`${this.testMode ? "test_" : ""}networks`)
        .orderBy("order")
        .onSnapshot((networks) => {
          this.networks = networks.docs.map((doc) => doc.data());
        });
    },
  },
  mounted: function () {
    this.getItems();
  },
  beforeDestroy: function () {
    if (this.pageRef) this.pageRef();
    if (this.networksRef) this.networksRef();
  },
};
</script>

<style scoped>
.top-section {
  position: relative;
  margin-bottom: 258px;
}

.top-section .container {
  position: relative;
  display: flex;
  align-items: center;
  background: rgba(28, 172, 214, 0.08);
  border-radius: 16px;
  min-height: 760px;
  padding: 48px 0;
  margin-right: 144px;
}

.top-section .container .content {
  position: relative;
  width: calc(50% + 72px);
  padding: 0 42px 0 96px;
  min-width: 420px;
  z-index: 2;
}

.top-section .container .content h3 {
  font-size: 72px;
}

.top-section .container .content h5 {
  margin: 16px 0 32px;
  line-height: 1.2;
  color: #656662;
}

.top-section .container .content p {
  font-size: 18px;
}

.top-section .content .buttons {
  margin-top: 64px;
}

.top-section .section-image {
  position: absolute;
  top: 24px;
  right: -144px;
  width: calc(50% + 72px);
  bottom: 24px;
  border-radius: 16px;
  background-color: #fafafa;
  overflow: hidden;
}

.top-section:before {
  content: '';
  position: absolute;
  width: 7px;
  height: 192px;
  left: 25%;
  top: 100%;
  margin-top: 32px;
  background: url(../assets/images/wb_o_spacer.svg) no-repeat center/contain;
}

.top-section .content .title {
  display: flex;
  align-items: center;
  color: #1dacd6;
  font-size: 20px;
  margin-bottom: 24px;
}

.top-section .content .title:before {
  content: '';
  display: block;
  width: 112px;
  height: 21px;
  background: url(../assets/images/wb_o_4dots.svg) no-repeat center/contain;
  margin-right: 24px;
}

.top-section .section-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
}

.what-we-do {
  background: #fafafa;
  padding: 72px 0;
  margin-bottom: 96px;
}

.what-we-do .container {
  display: flex;
  align-items: center;
}

.what-we-do .container .content {
  width: 715px;
  margin: 0 96px;
  flex-shrink: 0;
}

.what-we-do .container .content h3:before {
  content: "";
  display: block;
  width: 110px;
  height: 21px;
  background: url(../assets/images/wb_o_4dots.svg) no-repeat center/contain;
  margin-bottom: 24px;
}

.what-we-do .container .content h5 {
  color: #656662;
  margin: 24px 0 32px;
  line-height: 1.2;
}

.what-we-do .container .content .buttons {
  margin-top: 64px;
}

.what-we-do .image-container {
  flex-grow: 1;
}

.what-we-do .image {
  position: relative;
  padding-top: 100%;
}

.what-we-do .image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  object-fit: cover;
}

.networks {
  margin-bottom: 64px;
}

.networks:after {
  content: '';
  display: block;
  width: 7px;
  height: 192px;
  margin: auto;
  background: url(../assets/images/wb_o_spacer.svg) no-repeat center/contain;
}

.networks .container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.networks .network {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(25% - 8px);
  height: 272px;
  padding: 48px;
  border-radius: 16px;
  border: 1px solid rgba(101, 102, 98, 0.08);
  box-shadow: 0 16px 24px rgba(52, 52, 52, 0.08);
  margin-bottom: 64px;
  transition: all .2s ease-out;
}

#app[no-touch] .networks .network:hover {
  box-shadow: 0 24px 32px rgba(52, 52, 52, 0.12);
  transform: translateY(-8px);
}

.networks .network .icon {
  position: absolute;
  top: 16px;
  right: 16px;
  opacity: 0;
  transition: opacity 0.2s ease-out;
  color: #1DACD6;
}

#app[no-touch] .networks .network:hover .icon {
  opacity: 1;
}

.networks .network img {
  display: block;
  width: 278px;
  height: 125px;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.video {
  text-align: center;
  margin-bottom: 96px;
}

.video .iframe {
  position: relative;
  background: #fafafa;
  padding-top: 56.25%;
  margin-top: 64px;
}

.video .iframe iframe {
  position: absolute;
  top: 16px;
  left: 16px;
  width: calc(100% - 32px);
  height: calc(100% - 32px);
}

.downloads {
  margin-top: 24px;
}

.downloads a {
  margin: 6px 12px;
}

.downloads a .ic {
  margin: 0 8px 0 0;
}

@media screen and (max-width: 1280px) {
  .top-section .container .content {
    padding-left: 64px;
  }
  .what-we-do .container .content {
    margin: 0 0 0 64px;
    max-width: 50%;
  }
  .networks .network {
    height: 232px;
  }
}

@media screen and (max-width: 1024px) {
  .top-section:before {
    left: 0;
    right: 0;
    margin: 32px auto 0;
  }
  .top-section .section-image {
    display: none;
  }
  .top-section .container {
    min-height: auto;
    margin-right: 0;
  }
  .top-section .container .content {
    width: 100%;
    min-width: 0;
  }
  .what-we-do {
    padding: 48px 0;
  }
  .what-we-do .container {
    flex-direction: column;
    align-items: flex-start;
  }
  .what-we-do .image-container {
    width: 100%;
  }
  .what-we-do .image {
    padding-top: 35%;
    margin-bottom: 48px;
  }
  .what-we-do .container .content {
    width: auto;
    max-width: none;
    margin: 0 24px;
  }
  .what-we-do .container .content h3:after {
    display: none;
  }
  .networks .network {
    height: 180px;
    padding: 24px;
    margin-bottom: 48px;
  }
}

@media screen and (max-width: 850px) {
  .what-we-do .container .content {
    margin: 0 48px;
  }
  .what-we-do {
    margin-bottom: 64px;
  }
}

@media screen and (max-width: 800px) {
  .top-section .container .content {
    margin: 0;
    padding: 48px;
    max-width: none;
  }
  .networks .network {
    width: calc(50% - 8px);
    padding: 24px 48px;
  }
}

@media screen and (max-width: 620px) {
  .what-we-do .container .content {
    margin: 0 24px;
  }
}

@media screen and (max-width: 480px) {
  .top-section .container .content {
    padding: 32px;
  }
  .networks .network {
    width: 100%;
    margin-bottom: 24px;
  }
}
</style>
